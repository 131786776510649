<template>
  <Layout>
    <PageHeader :items="items"/>

    <!--FILTER START------>
    <div class="card" style="margin-top: -30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <!-- Country List-->
          <div class="col-md-2" style="width: 12%">
            <label>Country </label>
            <multiselect
                v-model="country"
                :options="countryArr"
                :show-labels="false"
                label="country"
                track-by="country"
                @input="getAllCities()"
            ></multiselect>
          </div>

          <div class="col-md-2" style="width: 12%">
            <label>City </label>
            <multiselect
                v-model="city"
                :options="cityArr"
                :show-labels="false"
                label="city"
                track-by="city"
                @input="getCorporates(),getAllRestaurantBranches()"
            ></multiselect>
          </div>
          <div class="col-md-3">
            <label class="form-label" style="font-size: 12px"
            >Select Corporate
            </label>
            <multiselect
                v-model="corporateID"
                :options="corporateArr"
                :show-labels="false"
                label="corporateName"
                track-by="corporateID"
                @input="getCorporateBranches()"
            ></multiselect>
          </div>
          <div class="col-md-2">
            <label class="form-label" style="font-size: 12px"
            >Select Corporate Branch
            </label>
            <multiselect
                v-model="corpBranchID"
                :options="corporateBranches"
                :show-labels="false"
                label="branchName"
                track-by="corpBranchID"
                @input="getAllRestaurantBranches()"
            ></multiselect>
          </div>
          <div class="col-md-3">
            <label>Restaurant Branch </label>
            <multiselect
                v-model="branchID"
                :options="restaurantBranches"
                :show-labels="false"
                label="branchName"
                track-by="restBranchID"
                @input="onchangeBranch()"
            ></multiselect>
          </div>
        </div>
        <div class="inner mb-2 row">
          <!-- Date range -->
          <div class="col-md-3">
            <label>Date Range </label>
            <date-picker
                v-model="daterange"
                append-to-body
                confirm
                format="DD MMM Y"
                lang="en"
                range
            ></date-picker>
          </div>

          <div class="col-md-3">
            <label>Order Type </label>
            <multiselect
                v-model="orderType"
                :options="orderTypeArr"
                :show-labels="false"
            ></multiselect>
          </div>
          <!-- Order Type END-->

          <!-- Apply filter -->
          <div class="col-md-2" style="width: auto; margin-top: auto">
            <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()">
              Apply Filter
            </button>
          </div>
          <!-- Apply filter END-->

          <!-- Clear filter -->
          <div class="col-md-2" style="width: auto; margin-top: auto">
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
          <!-- Clear filter END-->
        </div>
      </div>
    </div>
    <!--FILTER END------>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div v-if="isLoading == 1" class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading Please wait...</strong>
          </div>
          <b-tabs
              content-class="p-3 text-muted"
              justified
              nav-class="nav-tabs-custom"
          >
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block">Sodexo Report Overview</span>
              </template>
              <div class="row" style="margin-top: 10px;">
                <div class="col-md-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="mb-1 mt-1">
                          {{ overViewData.sumGrandTotal ? $func.formatPrice(overViewData.sumGrandTotal) : 0 }}
                        </h4>
                        <p class="text-muted mb-0">Sodexo Sales</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="mb-1 mt-1">
                          {{ overViewData.totalOrders ? $func.formatCommaSepNum(overViewData.totalOrders) : 0 }}
                         </h4>
                        <p class="text-muted mb-0">Delivered Orders</p>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="mb-1 mt-1">
                          {{ overViewData.rejectedOrders ? $func.formatCommaSepNum(overViewData.rejectedOrders) : 0 }}
                          </h4>
                        <p class="text-muted mb-0">Rejected Orders</p>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="col-md-6 col-xl-3">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-body">
                          <div>
                            <h4 class="mb-1 mt-1">
                              {{ overViewData.totalUsers ? $func.formatCommaSepNum(overViewData.totalUsers) : 0 }}
                            </h4>
                            <p class="text-muted mb-0">Total Users</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-body">
                          <div>
                            <h4 class="mb-1 mt-1">
                                {{ overViewData.refundedAmount ? $func.formatPrice(overViewData.refundedAmount) : 0 }}
                              </h4>
                            <p class="text-muted mb-0">Refunded Amount</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-lg-12">
                <div class="card">
                  <div class="card-body">
                    <h6 class="mb-4">Sodexo Sales Graph</h6>
                    <!-- Bar Chart -->
                    <apexchart
                        v-if="salesGraph.salesGraph"
                        :options="salesGraph.salesGraph.chartOptions"
                        :series="salesGraph.salesGraph.series"
                        class="apex-charts"
                        dir="ltr"
                        height="339"
                        type="area"
                    ></apexchart>
                  </div>
                </div>
              </div>
            </b-tab>

            <!-----END of Sales Overview----->
            <b-tab>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block">Sodexo Report List</span>
              </template>
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div
                          class="col-sm-12 col-md-4"
                          style="margin-bottom: 5px"
                      >
                        <div class="btn-group" role="group">
                          <button
                              v-if="this.$storageData.profile.empTypeID == 1"
                              class="btn btn-themeBrown"
                              type="button"
                              @click="downloadSample()"
                          >
                            Excel
                          </button>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-4">
                        <div
                            id="tickets-table_length"
                            class="dataTables_length"
                        >
                          <label class="d-inline-flex align-items-center">
                            Show &nbsp;&nbsp;
                            <b-form-select
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                                style="margin-left: 5px; margin-right: 5px"
                                @input="viewSalesReport();"
                            ></b-form-select
                            >&nbsp;&nbsp;&nbsp; entries
                          </label>
                        </div>
                      </div>
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                      <b-table
                          :busy="isReportTableBusy"
                          :fields="fields"
                          :filter="filter"
                          :filter-included-fields="filterOn"
                          :items="tableItems"
                          :per-page="0"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          bordered
                          foot-clone
                          hover
                          outlined
                          responsive
                          striped
                      >
                        <template #table-busy>
                          <div class="text-center text-danger my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Loading...</strong>
                          </div>
                        </template>
                        <template v-slot:cell(orderID)="data">
                          <div>
                            <span style="cursor: pointer; color: rgb(243, 118, 106);"
                                  @click="viewOrderDetails(data.item.id)">
                              {{ data.item.orderID }}
                            </span>
                          </div>
                        </template>

                        <template v-slot:cell(employeeName)="data">
                          <span> {{ data.item.userName }}</span>
                        </template>

                        <template v-slot:cell(orderStatus)="data">
                          <span
                              :style="
                              data.item.orderStatus == 'Rejected'
                                ? 'color:#f3766a;'
                                : ''
                            "
                          >
                            {{ data.item.orderStatus }}</span
                          >
                        </template>

                        <template v-slot:head(corpSubsidy)>
                          <span> Meal Plan</span>
                        </template>

                        <template v-slot:head(orderStatus)>
                          <span> Ord Status</span>
                        </template>

                        <template v-slot:foot(orderID)>
                          <span>Total: </span>
                        </template>
                        <template v-slot:foot(employeeName)>
                          <span></span>
                        </template>
                        <template v-slot:foot(employeeID)>
                          <span></span>
                        </template>
                        <template v-slot:foot(restaurant)>
                          <span></span>
                        </template>
                        <template v-slot:foot(orderStatus)>
                          <span></span>
                        </template>
                        <template v-slot:foot(cancelReason)>
                          <span></span>
                        </template>

                        <template v-slot:foot(itemTotal)>
                          <p class="align-right" style="margin: 0">
                            {{ $func.formatPrice(overViewData.sumItemTotal) }}
                          </p>
                        </template>
                        <template v-slot:foot(cpnDisc)>
                          <p class="align-right" style="margin: 0">
                            {{ $func.formatPrice(overViewData.sumCpnDisc) }}
                          </p>
                        </template>
                        <template v-slot:foot(otherChrgs)>
                          <p class="align-right" style="margin: 0">
                            {{ $func.formatPrice(overViewData.sumOtherChrgs) }}
                          </p>
                        </template>
                        <template v-slot:foot(taxes)>
                          <p class="align-right" style="margin: 0">
                            {{ $func.formatPrice(overViewData.sumTaxes) }}
                          </p>
                        </template>
                        <template v-slot:foot(grandTotal)>
                          <p class="align-right" style="margin: 0">
                            {{ $func.formatPrice(overViewData.sumGrandTotal) }}
                          </p>
                        </template>
                        <template v-slot:foot(corpSubsidy)>
                          <span></span>
                        </template>
                        <template v-slot:foot(wallet)>
                          <p class="align-right" style="margin: 0">
                            {{ $func.formatCommaSepNum(overViewData.sumWallet) }}
                          </p>
                        </template>
                        <template v-slot:foot(voucherRedm)>
                          <p class="align-right" style="margin: 0">
                            {{ $func.formatPrice(overViewData.sumVoucherRedm) }}
                          </p>
                        </template>
                        <template v-slot:foot(NetPay)>
                          <p class="align-right" style="margin: 0">
                            {{ $func.formatPrice(overViewData.sumNetPay) }}
                          </p>
                        </template>
                        <template v-slot:foot(orderType)>
                          <span></span>
                        </template>
                        <template v-slot:foot(paymentType)>
                          <span></span>
                        </template>
                        <template v-slot:foot(date)>
                          <span></span>
                        </template>
                      </b-table>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div
                            class="
                            dataTables_paginate
                            paging_simple_numbers
                            float-end
                          "
                        >
                          <ul class="pagination pagination-rounded mb-0">
                            <b-pagination v-model="currentPage" :per-page="tableTo" :total-rows="tableTotal"
                                          size="md"></b-pagination>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>

    <!-- modals -->
    <OrderReciept
        :orderDetails="this.orderDetails"
        :orderStatusArr="this.orderStatusArr"
        :orderedItemArr="this.orderedItemArr"
    >
    </OrderReciept>
  </Layout>
</template>

<script>
import Vue from "vue";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import OrderReciept from "@/components/orderReciept";
import DatePicker from "vue2-datepicker";
import VueApexCharts from 'vue-apexcharts'

Vue.component('apexchart', VueApexCharts)

export default {
  name: "SodexoReports",
  page: {
    title: "Sodexo Reports",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {Layout, PageHeader, Multiselect, OrderReciept, DatePicker},
  data() {
    return {
      corporateID: '',
      corporateArr: [],
      dateFilter: [],
      isReportTableBusy: false,
      tableData: [],
      tableTotal: '',
      tableTo: '',
      tableItems: [],
      tableExcelData: [],
      title: "Sodexo Report",
      items: [
        {
          text: "View",
        },
        {
          text: "Sodexo Report",
          active: true,
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      daterange: [],
      orderDetails: [],
      corporateBranches: [],
      restaurantBranches: [],
      branchID: "",
      corpBranchID: "",
      restBranchID: "",
      cityArr: [],
      city: "",
      countryArr: [],
      country: "",
      brandData: "",
      overViewData: [],
      salesGraph: [],
      orderedItemArr: [],
      orderStatusArr: [],
      paymentType: "",
      paymentTypeArr: ["Online", "Wallet", "Subsidy", "Pre-buy voucher", "COD"],
      orderTypeArr: [
        "Cafeteria / Food Court",
        "Near by Restaurant",
      ],
      orderType: "",
      orderStatus: "",
      isLoading: 0,
      fields: [
        {
          key: "orderID",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "employeeName",
          label: "Customer Name",
          sortable: true,
        },
        {
          key: "employeeID",
          label: "Customer ID",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "restaurant",
          sortable: true,
        },
        {
          key: "itemTotal",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "cpnDisc",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "otherChrgs",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "taxes",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "grandTotal",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "grandTotal",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "corpSubsidy",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "wallet",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "voucherRedm",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "NetPay",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "orderType",
          sortable: true,
        },
        {
          key: "paymentType",
          sortable: true,
        },
        {
          key: "orderStatus",
          sortable: true,
        },
        {
          key: "cancelReason",
          sortable: true,
        },
        {
          key: "date",
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    this.totalRows = this.items.length;
    this.getAllCountry();
  },
  computed: {
    rows() {
      return this.tableItems.length;
    },
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    viewOrderDetails(orderID) {
      this.$root.$emit("bv::show::modal", "modal-lg-orderDetails");
      this.axios
          .post(this.$loggedRole+"/getOrderDetailsByID", {orderID: orderID})
          .then((result) => {
            this.orderDetails = result.data.data;
            this.orderStatusArr = result.data.data.orderStatus;
            this.orderedItemArr = result.data.data.orderedItemArr;
          });
    },
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios
          .post(this.$loggedRole+"/getAllCountry", {
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
          })
          .then((response) => {
            this.countryArr = response.data.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getAllCities() {
      this.axios
          .post(this.$loggedRole+"/getAllCities", {
            country: this.country ? this.country.country : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
          })
          .then((response) => {
            this.cityArr = response.data.data;
          }).catch((error)=>{
            console.log(error);
      });
    },
    getCorporates(){
      if(this.country.length==0 || this.country=="undefined")
      {
        alert('Please select country.');
      }
      else if(this.city.length == 0 || this.city=="undefined"){
        alert('Please select city.')
      }
      else {
        this.corporateArr = [];
        this.corporateID = "";
        this.axios
            .post(this.$loggedRole+"/sodexo/corporate-lists", {
              empTypeID: this.$storageData.profile.empTypeID,
              empID: this.$storageData.profile.pid,
              loginTypeID: this.$storageData.login_type,
              country: this.country ? this.country.country : "",
              city: this.city ? this.city.city : "",
            })
            .then((response) => {
              if(response.data.status==200) {
                this.corporateArr = response.data.data;
              }
              else{
                alert(response.data.message);
              }
            });
      }
    },
    getCorporateBranches(){


      if(this.country.length==0 || this.country=="undefined")
      {
        alert('Please select country.');
      }
      else if(this.city.length == 0 || this.city=="undefined"){
        alert('Please select city.')
      }
      else if(this.corporateID==null || this.corporateID.length == 0 || this.corporateID=="undefined"){
        alert('Please select corporate.')
      }
      else {
        this.axios
            .post(this.$loggedRole+"/sodexo/corporate-branches", {
              country: this.country ? this.country.country : "",
              city: this.city ? this.city.city : "",
              empTypeID: this.$storageData.profile.empTypeID,
              empID: this.$storageData.profile.pid,
              loginTypeID: this.$storageData.login_type,
              corporateID: this.corporateID!==null ? this.corporateID.corporateID:"",
            })
            .then((response) => {
              if (response.data.status == 200) {
                this.corporateBranches = response.data.data;
              } else {
                alert(response.data.message);
              }
            });
      }
    },
    getAllRestaurantBranches()
    {
      if(this.country.length==0 || this.country=="undefined")
      {
        alert('Please select country.');
      }
      else if(this.city.length == 0 || this.city=="undefined"){
        alert('Please select city.')
      }
      else {
        this.axios
            .post(this.$loggedRole+"/sodexo/restaurant-branches", {
              country: this.country ? this.country.country : "",
              city: this.city ? this.city.city : "",
              empTypeID: this.$storageData.profile.empTypeID,
              empID: this.$storageData.profile.pid,
              loginTypeID: this.$storageData.login_type,
              corporateID: this.corporateID!==null ? this.corporateID.corporateID : "",
              corpBranchID: this.corpBranchID!==null ? this.corpBranchID.corpBranchID : "",
            })
            .then((response) => {
              if (response.data.status == 200) {
                this.restaurantBranches = response.data.data;
              } else {
                alert(response.data.message);
              }
            });
      }
    },

    onchangeBranch() {
      this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
    },
    viewSalesReport() {
      this.isLoading = 1;
      this.isReportTableBusy = true;
      this.axios
          .post(this.$loggedRole+"/sodexo/view-sales-report?page=1", {
            corpBranchID: this.corpBranchID!==null ? this.corpBranchID.corpBranchID : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            restBranchID: this.branchID!==null ? this.branchID.restBranchID : "",
            daterange: this.daterange,
            orderType: this.orderType!==null ? this.orderType:"",
            loginTypeID: this.$storageData.login_type,
            perPage: this.perPage,// new Added
            country: this.country!==null ? this.country.country : "",
            city: this.city!==null ? this.city.city : "",
          })
          .then((response) => {
            this.isLoading = 0;
            this.isReportTableBusy = false;
            if(this.perPage!="All")
            {
              this.tableData = response.data;
              this.tableTotal = this.tableData.data.total;
              this.tableTo = this.tableData.data.to;
              this.tableExcelData= this.tableItems = response.data.data.data;
              this.overViewData = response.data.overViewData;
            }
            else{
              this.tableExcelData= this.tableItems = response.data.data;
              this.overViewData = response.data.overViewData;
              this.tableTotal=1;
              this.tableTo=1;
            }

              this.salesReportGraph();
          });
    },

    viewSalesReportPerPage(currentPage) {
      this.isLoading = 1;
      this.isReportTableBusy = true;
      this.axios
          .post('sodexo/view-sales-report?page=' + currentPage, {
            corpBranchID: this.corpBranchID!==null ? this.corpBranchID.corpBranchID : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            restBranchID: this.branchID!==null ? this.branchID.restBranchID : "",
            daterange: this.daterange,
            orderType: this.orderType!==null ? this.orderType:"",
            loginTypeID: this.$storageData.login_type,
            perPage: this.perPage,// new Added
            country: this.country!==null ? this.country.country : "",
            city: this.city!==null ? this.city.city : "",
          })
          .then((response) => {
            this.isLoading = 0;
            this.isReportTableBusy = false;
            this.tableExcelData= this.tableItems = response.data.data.data;
          });
    },

    salesReportGraph() {
      this.isLoading = 1;
      this.axios
          .post(this.$loggedRole+"/sodexo/sales-graph", {
            corpBranchID: this.corpBranchID!==null ? this.corpBranchID.corpBranchID : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            restBranchID: this.branchID!==null ? this.branchID.restBranchID : "",
            daterange: this.daterange,
            orderType: this.orderType!==null ? this.orderType:"",
            loginTypeID: this.$storageData.login_type,
            country: this.country!==null ? this.country.country : "",
            city: this.city!==null ? this.city.city : "",
          })
          .then((result) => {
            this.isLoading = 0;
            this.salesGraph = result.data.data;
          });
    },

    downloadSample() {
      this.axios
          .post(
              "exportExcel",
              {
                exportData: this.tableExcelData,
                page: "SalesReport",
              },
              {
                responseType: "blob",
              }
          )
          .then((response) => {
            const url = URL.createObjectURL(
                new Blob([response.data], {
                  type: "application/vnd.ms-excel",
                })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "sales-report.xlsx");
            document.body.appendChild(link);
            link.click();
          });
    },

    applyFilter() {
      if (this.daterange.includes(null)) {
        this.daterange = [];
      }
      this.viewSalesReport();
    },

    clearFilter() {
      this.daterange = [];
      this.corporateArr = [];
      this.restBranchID = "";
      this.corporateID = "";
      this.country = "";
      this.city = "";
      this.cityArr = [];
      this.branchID = "";
      this.orderType = "";
      this.orderTypeArr=[];
      this.corpBranchID = "";
      this.corporateBranches=[];
      this.tableItems=[];
    },
  },
  watch: {
    currentPage: function (newVal) {
      this.viewSalesReportPerPage(newVal);
    }
  },
  middleware: "authentication",
}
</script>

<style scoped>
.cardBody {
  min-width: 180px !important;
  width: fit-content !important;
}

.cardTitle {
  font-size: 18px;
}

.cardSubtitle1 span {
  color: #a4827d;
  font-weight: 500;
  font-size: 15.5px;
}

.cardSubtitle2 p {
  color: #f3766a;
  font-weight: 500;
  font-size: 17px;
  margin: 0;
}

.overviewCard {
  padding: 0 5px !important;
}

.multiselect__content-wrapper {
  width: fit-content;
}
</style>